body {
  min-width: 1200px;
}
.purple-header{
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background: linear-gradient(-45deg, rgba(78, 84, 200, .9), rgba(128, 137, 255, .9));
  display: block;
  height: 115px;
}

.navbar {
  box-shadow: none !important;
}

.btn-primary {
  color: white;
  background: linear-gradient(90deg, #004dcc 0%, #00baff 100%);
  font-size: 20px;
  margin-left: 10px;
}

.btn-white {
  color: #185a9d;
  background: white;
  font-size: 20px;

}

.white {
  color: white !important;
}

.logo-login{
  height: 40px;
}

.login-form {
  margin-top: 80px;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  color: white;
}

.login-form label {
  background: rgb(232, 240, 254) !important;
  font-size: 32px;
  top: -5px !important;
  border-radius: 5px;
}
.login-form p {
  font-size: 25px;
  margin: 0;
}

.logo-div {
  padding: 10px 42px;
}

.login-form input,.login-form input:focus,.login-form input:hover {
  border: 1px solid white!important;
  border-radius: 4px;
}

.bg-main {
  background: linear-gradient(90deg, #004dcc 0%, #00baff 100%)
}
.main-menu {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
}

.makeStyles-mainMenu-1 div.MuiListItem-button,.main-menu div.MuiListItem-button, {
  width: 200px;
  background: white;
  border: 1px solid rgb(78, 84, 200);
  color: black !important;
}

.col-right{
  text-align: right;
}

.griddle-next-button,.griddle-previous-button {
  background: linear-gradient(90deg, #004dcc 0%, #00baff 100%);
  color:white;
  border: none;
}

.dateDescription {
  margin-left: 20px;
}

.custom-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-height: 70px;
  margin-top: 20px;
}
.custom-date input {
  width: 150px;
}

.first-row{
  margin-top: 110px;
}

.paper-stat{
  width: 220px;
  height: 100px;
  margin: 20px 0;
  background: #004dcc !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff !important;
}
.paper-stat .left {
  font-size: 20px;
}

.paper-stat .right {
  font-size: 40px;
}

.paper-stat .MuiSvgIcon-root {
  font-size: 40px;
  margin-bottom: 6px;
}

.red {
  color: red;
}

.green {
  color: greenyellow;
}


.custom-date .custom-col {
  display: flex;
  flex-direction: row;
  width: 350px;
  align-items: center;
  justify-content: space-around;
}

.finance-table .griddle-table {
  width: 80% !important;
}

.finance-table .griddle-table th:nth-child(3),.finance-table .griddle-table td:nth-child(3) {
  padding-left: 10px;
}

.button-act a {
  color: white;
}

.button-act a:hover {
  color: white;
  text-decoration: none !important;
}

a {
  color: #004dcc;
}

.content .row:first-child {
  margin-top: 100px;
}

.card-balance {
  font-size: 20px;
  padding: 15px;
}

.mt-100 {
  margin-top: 100px;
}

.create-campaign {
  width: 600px;
  margin: auto;
  font-size: 18px;
}

.create-campaign .row {
  margin: 20px 0;
}